import React, { useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { toast } from 'react-toastify';
import { XMarkIcon } from '@heroicons/react/24/outline';
import api from '../api'; // Import the api module

const GoalForm = ({ isOpen, onClose, refreshGoals, refreshLeaderboard, setCurrentStreak }) => {
    const [title, setTitle] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [specificDays, setSpecificDays] = useState([]);
    const [everyday, setEveryday] = useState(true);

    const calculateDuration = (start, end) => {
        const startDate = new Date(start);
        const endDate = new Date(end);
        const timeDiff = Math.abs(endDate - startDate);
        return Math.ceil(timeDiff / (1000 * 60 * 60 * 24)) + 1; // Include both start and end date
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const duration = calculateDuration(startDate, endDate);
        try {
            const response = await api.post('/api/goals', {
                title,
                duration,
                start_date: startDate,
                specific_days: everyday ? [] : specificDays,
            });
            const data = response.data;
            if (response.status === 201) {
                toast.success(data.message || 'Goal created successfully!');
                setTitle(''); // Clear the title input
                setStartDate(''); // Clear the start date input
                setEndDate(''); // Clear the end date input
                setSpecificDays([]); // Clear specific days selection
                setEveryday(true); // Reset everyday checkbox
                if (refreshGoals) refreshGoals();  // Call refreshGoals to update the goal list
                if (refreshLeaderboard) refreshLeaderboard();  // Call refreshLeaderboard to update the leaderboard
                fetchStreak();  // Update the streak
            } else {
                toast.error(data.error || 'Failed to create goal!');
            }
        } catch (error) {
            toast.error('Error creating goal');
        }
    };

    const fetchStreak = async () => {
        try {
            const response = await api.get('/api/streak');
            const data = response.data;
            if (response.status === 200) {
                setCurrentStreak(data.streak);
            } else {
                console.error('Failed to fetch streak');
            }
        } catch (error) {
            console.error('Error fetching streak:', error);
        }
    };

    const toggleSpecificDay = (day) => {
        setSpecificDays((prevDays) => 
            prevDays.includes(day) ? prevDays.filter((d) => d !== day) : [...prevDays, day]
        );
    };

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={onClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                <div className="flex justify-between items-center">
                                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                        Add New Goal
                                    </Dialog.Title>
                                    <button
                                        type="button"
                                        className="inline-flex justify-center rounded-md bg-white text-sm font-medium text-gray-500 hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                        onClick={onClose}
                                    >
                                        <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                                    </button>
                                </div>
                                <div className="mt-2">
                                    <form onSubmit={handleSubmit}>
                                        <div className="mb-4">
                                            <label className="block mb-2 text-gray-600" htmlFor="title">Title</label>
                                            <input
                                                id="title"
                                                type="text"
                                                placeholder="Goal Title"
                                                className="w-full border border-gray-300 p-2 rounded focus:outline-none"
                                                value={title}
                                                onChange={(e) => setTitle(e.target.value)}
                                            />
                                        </div>
                                        <div className="mb-4">
                                            <label className="block mb-2 text-gray-600" htmlFor="startDate">Start Date</label>
                                            <input
                                                id="startDate"
                                                type="date"
                                                className="w-full border border-gray-300 p-2 rounded focus:outline-none"
                                                value={startDate}
                                                onChange={(e) => setStartDate(e.target.value)}
                                            />
                                        </div>
                                        <div className="mb-4">
                                            <label className="block mb-2 text-gray-600" htmlFor="endDate">End Date</label>
                                            <input
                                                id="endDate"
                                                type="date"
                                                className="w-full border border-gray-300 p-2 rounded focus:outline-none"
                                                value={endDate}
                                                onChange={(e) => setEndDate(e.target.value)}
                                            />
                                        </div>
                                        <div className="mb-4 flex items-center">
                                            <input
                                                id="everyday"
                                                type="checkbox"
                                                className="mr-2"
                                                checked={everyday}
                                                onChange={(e) => setEveryday(e.target.checked)}
                                            />
                                            <label htmlFor="everyday" className="text-gray-600">Everyday</label>
                                        </div>
                                        {!everyday && (
                                            <div className="mb-4">
                                                <label className="block mb-2 text-gray-600">Specific Days</label>
                                                <div className="flex space-x-2">
                                                    {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map(day => (
                                                        <button
                                                            type="button"
                                                            key={day}
                                                            className={`px-3 py-1 rounded ${specificDays.includes(day) ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-600'}`}
                                                            onClick={() => toggleSpecificDay(day)}
                                                        >
                                                            {day}
                                                        </button>
                                                    ))}
                                                </div>
                                            </div>
                                        )}
                                        <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded-full w-full font-semibold">Add Goal</button>
                                    </form>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

export default GoalForm;
