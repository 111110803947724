// src/App.js

import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import Login from './components/Login';
import Register from './components/Register';
import Dashboard from './components/Dashboard';
// Import AdminDashboard only if you want it statically in the bundle
import AdminDashboard from './components/admin/AdminDashboard';
import PublicDashboard from './components/PublicDashboard';
import GoalList from './components/GoalList';
import ProtectedRoute from './components/ProtectedRoute';
import SharedProgress from './components/SharedProgress';
import VerifyEmail from './components/VerifyEmail';
import ResetPassword from './components/ResetPassword';
import NotFound from './components/NotFound';
import Profile from './components/Profile';
import CompleteProfile from './components/CompleteProfile';
import api from './api';
import { setToken, removeToken, getToken } from './auth';
import './App.css';

const imagePath = `${process.env.PUBLIC_URL}/loosing_weight.jpg`;

const App = () => {
    const location = useLocation();
    const [isAuthenticated, setIsAuthenticated] = useState(!!getToken());
    const [currentStreak, setCurrentStreak] = useState(0);
    const [profileComplete, setProfileComplete] = useState(false);
    const [userRole, setUserRole] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchStreak = async () => {
            try {
                const { data } = await api.get('/api/streak');
                setCurrentStreak(data.streak);
            } catch (error) {
                console.error('Error fetching streak:', error);
            }
        };

        if (isAuthenticated) {
            fetchStreak();
        }
    }, [isAuthenticated]);

    const handleLogin = (token, profileComplete, role) => {
        setToken(token);
        setIsAuthenticated(true);
        setProfileComplete(profileComplete);
        setUserRole(role);

        if (role === 'admin') {
            navigate('/admin/dashboard', { replace: true });
        } else if (profileComplete) {
            navigate('/dashboard', { replace: true });
        } else {
            navigate('/complete-profile', { replace: true });
        }
    };

    const handleProfileUpdate = () => {
        setProfileComplete(true);
        navigate('/dashboard', { replace: true });
    };

    const handleLogout = () => {
        removeToken();
        setIsAuthenticated(false);
        setProfileComplete(false);
        setUserRole(null);
        navigate('/login', { replace: true });
    };

    return (
        <SwitchTransition>
            <CSSTransition
                key={location.key}
                classNames="slide"
                timeout={300}
                unmountOnExit
            >
                <div className="route-wrapper">
                    <Routes location={location}>
                        {/* Public dashboard available to all users */}
                        <Route path="/" element={<PublicDashboard image={imagePath} />} />

                        {/* Auth-related routes */}
                        <Route
                            path="/login"
                            element={
                                isAuthenticated ? (
                                    userRole === 'admin' ? (
                                        <Navigate to="/admin/dashboard" replace />
                                    ) : profileComplete ? (
                                        <Navigate to="/dashboard" replace />
                                    ) : (
                                        <Navigate to="/complete-profile" replace />
                                    )
                                ) : (
                                    <Login onLogin={handleLogin} />
                                )
                            }
                        />
                        <Route
                            path="/register"
                            element={
                                isAuthenticated ? (
                                    profileComplete ? (
                                        <Navigate to="/dashboard" replace />
                                    ) : (
                                        <Navigate to="/complete-profile" replace />
                                    )
                                ) : (
                                    <Register />
                                )
                            }
                        />

                        {/* Conditionally render the admin route:
                           Only define it if isAuthenticated AND userRole === 'admin' */}
                        {isAuthenticated && userRole === 'admin' && (
                            <Route
                                path="/admin/dashboard"
                                element={
                                    <ProtectedRoute>
                                        <AdminDashboard onLogout={handleLogout} />
                                    </ProtectedRoute>
                                }
                            />
                        )}

                        {/* Protected routes */}
                        <Route
                            path="/dashboard"
                            element={
                                <ProtectedRoute>
                                    <Dashboard
                                        currentStreak={currentStreak}
                                        setCurrentStreak={setCurrentStreak}
                                        onLogout={handleLogout}
                                    />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/goals"
                            element={
                                <ProtectedRoute>
                                    <GoalList />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/profile"
                            element={
                                <ProtectedRoute>
                                    <Profile onProfileUpdate={handleProfileUpdate} />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/complete-profile"
                            element={
                                <ProtectedRoute>
                                    <CompleteProfile onProfileUpdate={handleProfileUpdate} />
                                </ProtectedRoute>
                            }
                        />

                        {/* Routes accessible without login */}
                        <Route path="/api/shared-progress/:share_id" element={<SharedProgress />} />
                        <Route path="/verify-email/:token" element={<VerifyEmail />} />
                        <Route path="/reset-password" element={<ResetPassword />} />

                        {/* 404 catch-all route */}
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </div>
            </CSSTransition>
        </SwitchTransition>
    );
};

export default App;
