import React from 'react';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { FaRegSmileWink } from 'react-icons/fa';

const GoalProgressChart = ({ goals }) => {
    const calculateCompletionPercentage = (goal) => {
        const totalDays = Object.keys(goal.progress).length;
        const completedDays = Object.values(goal.progress).filter(progress => progress.status === 'completed').length;
        return (completedDays / totalDays) * 100;
    };

    const calculateMissedPercentage = (goal) => {
        const totalDays = Object.keys(goal.progress).length;
        const missedDays = Object.values(goal.progress).filter(progress => progress.status === 'missed').length;
        return (missedDays / totalDays) * 100;
    };

    if (goals.length === 0) {
        return (
            <div className="flex flex-col items-center justify-center mt-4 p-4 bg-white rounded-lg shadow-lg">
                <FaRegSmileWink className="text-6xl text-gray-400 mb-4" />
                <p className="text-gray-600">You don't have any goals yet. Start by adding a new goal!</p>
            </div>
        );
    }

    return (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 mt-4">
            {goals.map((goal) => {
                const completionPercentage = calculateCompletionPercentage(goal);
                const missedPercentage = calculateMissedPercentage(goal);
                const totalPercentage = completionPercentage + missedPercentage;

                return (
                    <div key={goal._id} className="bg-white p-4 rounded-lg shadow-lg flex flex-col items-center">
                        <h2 className="text-xl font-bold mb-2">{goal.title}</h2>
                        <div style={{ width: 100, height: 100, position: 'relative' }}>
                            <CircularProgressbarWithChildren
                                value={totalPercentage}
                                styles={buildStyles({
                                    trailColor: '#d6d6d6',
                                })}
                            >
                                <div style={{ width: 100, height: 100, position: 'absolute', top: 0, left: 0 }}>
                                    <CircularProgressbarWithChildren
                                        value={missedPercentage}
                                        styles={buildStyles({
                                            pathColor: '#ff0000',
                                            trailColor: 'transparent',
                                        })}
                                    />
                                </div>
                                <div style={{ width: 100, height: 100, position: 'absolute', top: 0, left: 0 }}>
                                    <CircularProgressbarWithChildren
                                        value={completionPercentage}
                                        styles={buildStyles({
                                            pathColor: '#00ff00',
                                            trailColor: 'transparent',
                                        })}
                                    />
                                </div>
                                <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                    <strong>{`${Math.round(totalPercentage)}%`}</strong>
                                </div>
                            </CircularProgressbarWithChildren>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default GoalProgressChart;
