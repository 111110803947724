import React, { useState } from 'react';
import { LockClosedIcon, CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../api'; // Import the api module

const ChangePassword = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleChangePassword = async () => {
    if (newPassword === currentPassword) {
      toast.error('New password cannot be the same as the current password');
      return;
    }

    try {
      const response = await api.post('/auth/change-password', {
        currentPassword,
        newPassword,
        confirmPassword
      });

      if (response.status === 200) {
        setCurrentPassword('');
        setNewPassword('');
        setConfirmPassword('');
        toast.success('Password updated successfully');
      } else {
        console.error('Failed to update password');
        toast.error('Failed to update password');
      }
    } catch (error) {
      console.error('Error updating password:', error);
      toast.error('Error updating password');
    }
  };

  return (
    <div className="max-w-3xl mx-auto mt-10 p-6 bg-white rounded-lg shadow-lg">
      <ToastContainer />
      <h2 className="text-3xl font-bold mb-6 text-center">Change Password</h2>
      <div className="mb-6">
        <label className="block text-gray-700 text-sm font-bold mb-2">Current Password</label>
        <div className="flex items-center border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
          <LockClosedIcon className="h-5 w-5 text-gray-500 mr-3" />
          <input
            type="password"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            className="flex-1 bg-transparent focus:outline-none"
          />
        </div>
      </div>
      <div className="mb-6">
        <label className="block text-gray-700 text-sm font-bold mb-2">New Password</label>
        <div className="flex items-center border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
          <LockClosedIcon className="h-5 w-5 text-gray-500 mr-3" />
          <input
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            className="flex-1 bg-transparent focus:outline-none"
          />
        </div>
      </div>
      <div className="mb-6">
        <label className="block text-gray-700 text-sm font-bold mb-2">Confirm New Password</label>
        <div className="flex items-center border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
          <LockClosedIcon className="h-5 w-5 text-gray-500 mr-3" />
          <input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            className="flex-1 bg-transparent focus:outline-none"
          />
          {confirmPassword && (confirmPassword === newPassword ? (
            <CheckCircleIcon className="h-5 w-5 text-green-500 ml-2" />
          ) : (
            <XCircleIcon className="h-5 w-5 text-red-500 ml-2" />
          ))}
        </div>
        {confirmPassword && confirmPassword !== newPassword && (
          <p className="text-red-500 text-xs mt-2">Passwords do not match</p>
        )}
      </div>
      <button
        onClick={handleChangePassword}
        className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
      >
        Change Password
      </button>
    </div>
  );
};

export default ChangePassword;
