import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useSnackbar } from 'notistack';
import ForgotPasswordModal from './ForgotPasswordModal';
import api from '../api'; // Import your API module

const Login = ({ onLogin }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false); // Track loading state
    const navigate = useNavigate();
    const emailId = uuidv4();
    const passwordId = uuidv4();
    const rememberMeId = uuidv4();

    useEffect(() => {
        const checkToken = async () => {
            const token = localStorage.getItem('token');
            if (token) {
                try {
                    const response = await api.get('/auth/user');
                    if (response.status === 200) {
                        const userRole = response.data.role;
                        if (userRole === 'admin') {
                            navigate('/admin/dashboard'); // Redirect to admin dashboard
                        } else {
                            navigate('/dashboard'); // Redirect to user dashboard
                        }
                    }
                } catch (error) {
                    console.error('Token check failed:', error);
                }
            }
        };

        checkToken();
    }, [navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true); // Start loading
        try {
            const response = await api.post('/auth/login', { email, password });
            const { access_token, role, username, location } = response.data;

            enqueueSnackbar(response.data.message || 'Logged in successfully!', { variant: 'success' });
            localStorage.setItem('token', access_token);

            // Redirect based on role after successful login
            setTimeout(() => {
                onLogin(access_token, !!username && !!location, role); // Pass role to onLogin
                if (role === 'admin') {
                    navigate('/admin/dashboard'); // Admin dashboard
                } else {
                    navigate('/dashboard'); // User dashboard
                }
            }, 2000);
        } catch (error) {
            enqueueSnackbar(error.response?.data?.error || 'Failed to log in!', { variant: 'error' });
        } finally {
            setLoading(false); // Stop loading after request completes
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center font-poppins relative">
            {/* Background Gradient */}
            <div className="absolute inset-0 -z-10 bg-gradient-to-r from-blue-100 to-white"></div>

            {/* Diagonal Divider */}
            <div className="absolute inset-0 -z-20">
                <div className="w-full h-full bg-gray-100 clip-divider"></div>
            </div>

            <form 
                onSubmit={handleSubmit} 
                className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md z-10"
            >
                {/* App Name */}
                <div className="text-center mb-6">
                    <h1 className="text-4xl font-bold text-blue-500">GoalTracker</h1>
                    <p className="text-sm text-gray-600">Track your progress and achieve your goals!</p>
                </div>

                <h2 className="text-2xl mb-6 text-center font-semibold">Login</h2>

                <div className="mb-4">
                    <label htmlFor={emailId} className="block mb-2 text-gray-600">Email</label>
                    <div className="flex items-center border border-gray-300 p-2 rounded">
                        <i className="fas fa-envelope mr-2 text-gray-500"></i>
                        <input
                            id={emailId}
                            type="email"
                            placeholder="Email"
                            className="w-full focus:outline-none smooth-text"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                </div>

                <div className="mb-4">
                    <label htmlFor={passwordId} className="block mb-2 text-gray-600">Password</label>
                    <div className="flex items-center border border-gray-300 p-2 rounded">
                        <i className="fas fa-lock mr-2 text-gray-500"></i>
                        <input
                            id={passwordId}
                            type="password"
                            placeholder="Password"
                            className="w-full focus:outline-none smooth-text"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                </div>

                <div className="mb-4 flex items-center justify-between">
                    <div className="flex items-center">
                        <input type="checkbox" id={rememberMeId} className="mr-2" />
                        <label htmlFor={rememberMeId} className="text-gray-600">Remember Me</label>
                    </div>
                    <button
                        type="button"
                        onClick={() => setIsModalOpen(true)}
                        className="text-blue-500 hover:underline text-sm"
                    >
                        Forgot Password?
                    </button>
                </div>

                <button
                    type="submit"
                    className="bg-blue-500 text-white py-3 px-6 rounded-full w-full font-semibold flex items-center justify-center"
                    disabled={loading} // Disable button when loading
                >
                    {loading ? (
                        <svg
                            className="animate-spin h-5 w-5 mr-3"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                        >
                            <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                            ></circle>
                            <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8v8z"
                            ></path>
                        </svg>
                    ) : (
                        <>
                            <i className="fas fa-sign-in-alt mr-2"></i> Login
                        </>
                    )}
                </button>

                <div className="text-center mt-4">
                    <span className="text-gray-600">Don't have an account? </span>
                    <Link to="/register" className="text-blue-500 hover:underline">Register</Link>
                </div>
            </form>

            <ForgotPasswordModal
                isOpen={isModalOpen}
                onRequestClose={() => setIsModalOpen(false)}
            />
        </div>
    );
};

export default Login;
