import React from 'react';
import { FireIcon } from '@heroicons/react/24/solid';

const Streak = ({ currentStreak }) => {
    return (
        <div className="flex items-center bg-red-100 p-2 rounded-full">
            <FireIcon className="h-6 w-6 text-red-500 mr-2" />
            <div>
                <p className="text-red-500 font-bold">Streak: {currentStreak} days</p>
            </div>
        </div>
    );
};

export default Streak;
