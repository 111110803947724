import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import debounce from 'lodash.debounce';
import { useSnackbar } from 'notistack';
import './Login.css'; // Ensure any needed styles are available.

const Register = () => {
    const { enqueueSnackbar } = useSnackbar();
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [location, setLocation] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [emailAvailable, setEmailAvailable] = useState(true);
    const [usernameAvailable, setUsernameAvailable] = useState(true);
    const [loading, setLoading] = useState(false);
    const [selectedSuggestion, setSelectedSuggestion] = useState(false);

    const emailId = uuidv4();
    const usernameId = uuidv4();
    const passwordId = uuidv4();
    const confirmPasswordId = uuidv4();
    const locationId = uuidv4();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            enqueueSnackbar('Passwords do not match!', { variant: 'error' });
            return;
        }

        if (!emailAvailable || !usernameAvailable) {
            enqueueSnackbar('Email or Username not available!', { variant: 'error' });
            return;
        }

        if (!selectedSuggestion) {
            enqueueSnackbar('Please select a location from the suggestions!', { variant: 'error' });
            return;
        }

        setLoading(true);
        try {
            const response = await fetch('https://goaltracker-92af4b515b01.herokuapp.com/auth/register', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email, username, password, location }),
            });
            const data = await response.json();
            setLoading(false);

            if (response.ok) {
                enqueueSnackbar(data.message || 'Registered successfully!', { variant: 'success' });
                setEmail('');
                setUsername('');
                setPassword('');
                setConfirmPassword('');
                setLocation('');
                setSuggestions([]);
            } else {
                enqueueSnackbar(data.error || 'Failed to register!', { variant: 'error' });
            }
        } catch {
            setLoading(false);
            enqueueSnackbar('Failed to register!', { variant: 'error' });
        }
    };

    const debouncedCheckAvailability = useCallback(
        debounce(async (field, value) => {
            try {
                const response = await fetch(
                    `https://goaltracker-92af4b515b01.herokuapp.com/auth/check-${field}?value=${value}`
                );
                const data = await response.json();
                field === 'email' ? setEmailAvailable(data.available) : setUsernameAvailable(data.available);
            } catch {
                enqueueSnackbar(`Failed to check ${field} availability!`, { variant: 'error' });
            }
        }, 500),
        []
    );

    const fetchSuggestions = async (query) => {
        if (!query) return;
        try {
            const response = await fetch(
                `https://goaltracker-92af4b515b01.herokuapp.com/auth/location-suggestions?query=${query}`
            );
            const data = await response.json();
            setSuggestions(data.features.map(feature => ({
                location: `${feature.properties.city}, ${feature.properties.country}`,
            })));
            setSelectedSuggestion(false);
        } catch {
            enqueueSnackbar('Failed to fetch suggestions!', { variant: 'error' });
        }
    };

    const handleLocationChange = (e) => {
        setLocation(e.target.value);
        fetchSuggestions(e.target.value);
    };

    const handleSuggestionClick = (suggestion) => {
        setLocation(suggestion.location);
        setSuggestions([]);
        setSelectedSuggestion(true);
    };

    return (
        <div className="min-h-screen flex items-center justify-center font-poppins bg-gradient-to-br from-blue-50 to-gray-100 relative">
            <div className="w-full max-w-md bg-white p-8 rounded-lg shadow-lg z-10">
                <h1 className="text-3xl font-bold text-center text-blue-600 mb-6">GoalTracker</h1>
                <p className="text-center text-gray-500 mb-8">Achieve more with every goal!</p>

                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <label htmlFor={emailId} className="block mb-2 text-gray-600">Email</label>
                        <div className="relative">
                            <input
                                id={emailId}
                                type="email"
                                placeholder="Email"
                                className={`w-full p-2 border rounded focus:outline-none ${!emailAvailable ? 'border-red-500' : ''}`}
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                    debouncedCheckAvailability('email', e.target.value);
                                }}
                            />
                            {!emailAvailable && <span className="text-red-500 absolute right-2 top-1/2 transform -translate-y-1/2">✖</span>}
                        </div>
                    </div>

                    <div>
                        <label htmlFor={usernameId} className="block mb-2 text-gray-600">Username</label>
                        <div className="relative">
                            <input
                                id={usernameId}
                                type="text"
                                placeholder="Username"
                                className={`w-full p-2 border rounded focus:outline-none ${!usernameAvailable ? 'border-red-500' : ''}`}
                                value={username}
                                onChange={(e) => {
                                    setUsername(e.target.value);
                                    debouncedCheckAvailability('username', e.target.value);
                                }}
                            />
                            {usernameAvailable && username && <span className="text-green-500 absolute right-2 top-1/2 transform -translate-y-1/2">✔ Available</span>}
                        </div>
                    </div>

                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <label htmlFor={passwordId} className="block mb-2 text-gray-600">Password</label>
                            <input
                                id={passwordId}
                                type="password"
                                placeholder="Password"
                                className="w-full p-2 border rounded focus:outline-none"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        <div>
                            <label htmlFor={confirmPasswordId} className="block mb-2 text-gray-600">Confirm Password</label>
                            <input
                                id={confirmPasswordId}
                                type="password"
                                placeholder="Confirm Password"
                                className="w-full p-2 border rounded focus:outline-none"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="relative">
                        <label htmlFor={locationId} className="block mb-2 text-gray-600">Location <span className="text-sm text-gray-500">(Used to determine your timezone)</span></label>
                        <input
                            id={locationId}
                            type="text"
                            placeholder="City, Country"
                            className="w-full p-2 border rounded focus:outline-none"
                            value={location}
                            onChange={handleLocationChange}
                        />
                        {suggestions.length > 0 && (
                            <ul className="absolute z-10 bg-white border rounded mt-1 max-h-48 overflow-auto">
                                {suggestions.map((suggestion, index) => (
                                    <li
                                        key={index}
                                        className="p-2 cursor-pointer hover:bg-gray-100"
                                        onClick={() => handleSuggestionClick(suggestion)}
                                    >
                                        {suggestion.location}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>

                    <button type="submit" className="bg-blue-500 text-white py-3 px-6 rounded-full w-full font-semibold" disabled={loading}>
                        {loading ? 'Registering...' : 'Register'}
                    </button>

                    <div className="text-center mt-4">
                        <span className="text-gray-600">Already have an account? </span>
                        <Link to="/login" className="text-blue-500 hover:underline">Login</Link>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Register;
