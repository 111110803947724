import React, { useEffect, useState } from 'react';
import { FaCheck, FaTimes, FaSquare, FaCalendarAlt, FaPercentage } from 'react-icons/fa';
import api from '../api'; // Assuming you have an api module for making API requests

const PartnerProgress = ({ partnerEmail, onClose }) => {
    const [partnerProgress, setPartnerProgress] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPartnerProgress = async () => {
            try {
                const response = await api.get(`/api/partners/progress?partner_email=${partnerEmail}`);
                if (response.status === 200) {
                    setPartnerProgress(response.data);
                } else {
                    setError(response.data.error);
                }
            } catch (err) {
                setError('Failed to fetch partner progress data.');
            } finally {
                setLoading(false);
            }
        };

        fetchPartnerProgress();
    }, [partnerEmail]);

    const renderProgress = (progress, duration) => {
        const today = new Date().getDate();
        const progressElements = [];

        for (let day = 1; day <= duration; day++) {
            let statusClass = '';
            let statusIcon = '';

            if (progress[day] && progress[day].status === 'completed') {
                statusClass = 'bg-green-500';
                statusIcon = <FaCheck />;
            } else if (progress[day] && progress[day].status === 'missed') {
                statusClass = 'bg-red-500';
                statusIcon = <FaTimes />;
            } else if (progress[day] && progress[day].status === 'not completed') {
                statusClass = 'bg-yellow-500';
                statusIcon = <FaSquare />;
            } else if (progress[day] && progress[day].status === 'future') {
                statusClass = 'bg-gray-300';
                statusIcon = <FaSquare />;
            } else if (day < today) {
                statusClass = 'bg-red-500';
                statusIcon = <FaTimes />;
            } else {
                statusClass = 'bg-gray-300';
                statusIcon = <FaSquare />;
            }

            progressElements.push(
                <div key={`progress-${day}`} className={`p-0.5 ${statusClass} text-white text-center rounded text-xs`}>
                    {statusIcon}
                </div>
            );
        }

        return progressElements;
    };

    const formatGoalDuration = (duration) => {
        return `${duration} day${duration > 1 ? 's' : ''}`;
    };

    const calculateCompletionPercentage = (progress, duration) => {
        const completedDays = Object.values(progress).filter(day => day.status === 'completed').length;
        return Math.round((completedDays / duration) * 100);
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    const { username, progress_data, message } = partnerProgress;

    return (
        <div className="w-full max-w-7xl mx-auto p-4 bg-white rounded-lg shadow-lg">
            <h2 className="text-3xl font-bold mb-4 text-center">Partner Progress</h2>
            <div className="text-center mb-6">
                <h3 className="text-2xl font-semibold text-blue-600">{username}</h3>
            </div>
            {message ? (
                <div className="text-center text-lg text-gray-500">{message}</div>
            ) : (
                <div>
                    {progress_data.map((goal, index) => (
                        <div key={`goal-${index}`} className="mb-8">
                            <h3 className="text-xl font-semibold flex items-center justify-between mb-2">
                                <span>{goal.title}</span>
                                <span className="text-sm font-normal flex items-center">
                                    <FaCalendarAlt className="mr-1" /> {formatGoalDuration(goal.duration)}
                                    <FaPercentage className="ml-4 mr-1" /> {calculateCompletionPercentage(goal.progress, goal.duration)}%
                                </span>
                            </h3>
                            <div className="grid grid-cols-7 gap-2">
                                {renderProgress(goal.progress, goal.duration)}
                            </div>
                        </div>
                    ))}
                </div>
            )}
            <div className="mt-8 text-center">
                <button
                    onClick={onClose}
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                    Close
                </button>
            </div>
        </div>
    );
};

export default PartnerProgress;
