import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FaMedal, FaFire, FaCheck, FaTimes, FaSquare, FaCalendarAlt, FaPercentage, FaUser } from 'react-icons/fa';

const SharedProgress = () => {
    const { share_id } = useParams();
    const [sharedProgress, setSharedProgress] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchSharedProgress = async () => {
            try {
                const response = await fetch(`https://goaltracker-92af4b515b01.herokuapp.com/api/shared-progress/${share_id}`);
                const data = await response.json();

                if (response.ok) {
                    setSharedProgress(data);
                } else {
                    setError(data.error);
                }
            } catch (err) {
                setError('Failed to fetch shared progress data.');
            } finally {
                setLoading(false);
            }
        };

        fetchSharedProgress();
    }, [share_id]);

    const renderProgress = (progress, duration) => {
        const today = new Date().getDate();
        const progressElements = [];

        for (let day = 1; day <= duration; day++) {
            let statusClass = '';
            let statusIcon = '';

            if (progress[day] && progress[day].status === 'completed') {
                statusClass = 'bg-green-500';
                statusIcon = <FaCheck />;
            } else if (progress[day] && progress[day].status === 'missed') {
                statusClass = 'bg-red-500';
                statusIcon = <FaTimes />;
            } else if (progress[day] && progress[day].status === 'future') {
                statusClass = 'bg-gray-300';
                statusIcon = <FaSquare />;
            } else if (day < today) {
                statusClass = 'bg-red-500';
                statusIcon = <FaTimes />;
            } else {
                statusClass = 'bg-gray-300';
                statusIcon = <FaSquare />;
            }

            progressElements.push(
                <div key={`progress-${day}`} className={`p-0.5 ${statusClass} text-white text-center rounded text-xs`}>
                    {statusIcon}
                </div>
            );
        }

        return progressElements;
    };

    const getMedalIcon = (rank) => {
        switch (rank) {
            case 1:
                return <FaMedal className="text-yellow-500" />;
            case 2:
                return <FaMedal className="text-gray-400" />;
            case 3:
                return <FaMedal className="text-orange-500" />;
            default:
                return null;
        }
    };

    const formatGoalDuration = (duration) => {
        return `${duration} day${duration > 1 ? 's' : ''}`;
    };

    const calculateCompletionPercentage = (progress, duration) => {
        const completedDays = Object.values(progress).filter(day => day.status === 'completed').length;
        return Math.round((completedDays / duration) * 100);
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    const { progress_data, user_streak, user_rank, username } = sharedProgress;

    return (
        <div className="p-4 bg-gray-100 font-poppins min-h-screen">
            <div className="bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6 mx-auto">
                <h2 className="text-2xl font-bold mb-4">Shared Progress</h2>

                <div className="mb-4 border-b pb-4">
                    <h3 className="text-xl font-semibold mb-2 flex items-center">
                        <FaUser className="mr-2 text-gray-600" /> 
                        User: <span className="ml-2 text-lg font-normal">{username}</span>
                    </h3>
                    {progress_data.map((goal, index) => (
                        <div key={`goal-${index}`} className="mb-4">
                            <h3 className="text-xl font-semibold flex items-center">
                                {goal.title} 
                                <span className="ml-2 text-sm font-normal flex items-center">
                                    <FaCalendarAlt className="mr-1" /> {formatGoalDuration(goal.duration)}
                                    <FaPercentage className="ml-2 mr-1" /> {calculateCompletionPercentage(goal.progress, goal.duration)}%
                                </span>
                            </h3>
                            <div className="grid grid-cols-7 gap-1">
                                {renderProgress(goal.progress, goal.duration)}
                            </div>
                        </div>
                    ))}
                </div>

                <div className="mb-4 p-4 bg-gray-100 rounded-lg shadow-md">
                    <h3 className="text-xl font-semibold mb-2 flex items-center">User Streak <FaFire className="ml-2 text-red-500" /></h3>
                    <p>Current Streak: <span className="font-bold">{user_streak.current_streak}</span></p>
                    <p>Longest Streak: <span className="font-bold">{user_streak.longest_streak}</span></p>
                </div>

                <div className="mb-4 p-4 bg-gray-100 rounded-lg shadow-md">
                    <h3 className="text-xl font-semibold mb-2">Your Rank</h3>
                    <p>Your rank is: <span className="font-bold">{user_rank}</span> {getMedalIcon(user_rank)}</p>
                </div>

                <div className="mt-4 text-center">
                    <p className="text-gray-500">Shared via <a href="http://www.goaltracker.online" className="text-blue-500 hover:underline">Goaltracker.online</a></p>
                </div>
            </div>
        </div>
    );
};

export default SharedProgress;
