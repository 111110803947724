import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaArrowUp } from 'react-icons/fa';
import { Helmet } from 'react-helmet-async'; // Import Helmet
import PublicGoalList from './PublicGoalList';
import Section from './Section';
import PublicGoalProgressChart from './PublicGoalProgressChart';
import Leaderboard from './Leaderboard';
import PublicHeader from './PublicHeader';
import BeforeAfter from './BeforeAfter'; // Import BeforeAfter component
import api from '../api';

const imagePath = `${process.env.PUBLIC_URL}/loosing_weight.jpg`; // Define image path

const PublicDashboard = () => {
    const [goals, setGoals] = useState([]);
    const [leaderboard, setLeaderboard] = useState([]);
    const [showScrollTopButton, setShowScrollTopButton] = useState(false);
    const navigate = useNavigate();

    // Fetch public goals from the backend
    const fetchPublicGoals = async () => {
        try {
            const response = await api.get('/api/goals/public');
            setGoals(response.data);
        } catch (error) {
            console.error('Error fetching public goals:', error);
        }
    };

    // Fetch public leaderboard from the backend
    const fetchPublicLeaderboard = async () => {
        try {
            const response = await api.get('/api/leaderboard/public');
            setLeaderboard(response.data);
        } catch (error) {
            console.error('Error fetching public leaderboard:', error);
        }
    };

    useEffect(() => {
        fetchPublicGoals();
        fetchPublicLeaderboard();
    }, []);

    const handleInteraction = () => {
        navigate('/login', { replace: true });
    };

    useEffect(() => {
        const handleScroll = () => {
            setShowScrollTopButton(window.pageYOffset > 300);
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <main>
            {/* Add Helmet for SEO */}
            <Helmet>
                <title>GoalTracker | Discover Public Goals and Achievements</title>
                <meta
                    name="description"
                    content="Explore public goals, track progress, and see the top achievers on GoalTracker. Join our community to achieve your personal and professional goals!"
                />
                <link rel="canonical" href="https://goaltracker.online/public-dashboard" />
                <meta property="og:title" content="GoalTracker | Discover Public Goals and Achievements" />
                <meta
                    property="og:description"
                    content="Explore public goals, track progress, and see the top achievers on GoalTracker. Join our community to achieve your personal and professional goals!"
                />
                <meta property="og:image" content="https://goaltracker.online/logo512.png" />
                <meta property="og:url" content="https://goaltracker.online/public-dashboard" />
                <meta name="twitter:title" content="GoalTracker | Public Dashboard" />
                <meta
                    name="twitter:description"
                    content="Explore public goals, track progress, and join our vibrant community at GoalTracker!"
                />
                <meta name="twitter:image" content="https://goaltracker.online/logo512.png" />
            </Helmet>

            {/* Header with SEO-friendly navigation */}
            <PublicHeader />
            <div className="min-h-screen p-4 bg-gray-100 font-poppins">
                <Section title="Explore Our Community's Goals">
                    <div className="flex flex-col lg:flex-row">
                        <article className="flex-1">
                            <PublicGoalList
                                goals={goals}
                                onInteract={handleInteraction}
                            />
                        </article>
                        <aside className="lg:w-1/5 mt-4 lg:mt-0 lg:ml-4">
                            {/* Leaderboard */}
                            <Leaderboard leaderboard={leaderboard} />
                            {/* Before and After Component underneath the Leaderboard */}
                            <BeforeAfter image={imagePath} />
                        </aside>
                    </div>
                </Section>
                <Section title="Goal Progress of the Community">
                    <PublicGoalProgressChart goals={goals} />
                </Section>
            </div>
            {showScrollTopButton && (
                <button
                    onClick={scrollToTop}
                    className="fixed bottom-8 right-8 bg-blue-500 text-white p-3 rounded-full shadow-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    aria-label="Scroll to Top"
                >
                    <FaArrowUp />
                </button>
            )}
        </main>
    );
};

export default PublicDashboard;
