import React, { useState } from 'react';
import { FacebookIcon, TwitterIcon, WhatsappIcon } from 'react-share';
import { FaMedal, FaFire, FaCheck, FaTimes, FaSquare, FaCalendarAlt, FaPercentage } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../api'; // Import the api module

const ShareProgressCard = ({ progressData, onClose, userStreak, userRank }) => {
  const [shareUrl, setShareUrl] = useState('');
  const [shareNow, setShareNow] = useState(false);

  const renderProgress = (progress, duration) => {
    const today = new Date().getDate();
    const progressElements = [];

    for (let day = 1; day <= duration; day++) {
      let statusClass = '';
      let statusIcon = '';

      if (progress[day] && progress[day].status === 'completed') {
        statusClass = 'bg-green-500';
        statusIcon = <FaCheck />;
      } else if (progress[day] && progress[day].status === 'missed') {
        statusClass = 'bg-red-500';
        statusIcon = <FaTimes />;
      } else if (progress[day] && progress[day].status === 'future') {
        statusClass = 'bg-gray-300';
        statusIcon = <FaSquare />;
      } else if (day < today) {
        statusClass = 'bg-red-500';
        statusIcon = <FaTimes />;
      } else {
        statusClass = 'bg-gray-300';
        statusIcon = <FaSquare />;
      }

      progressElements.push(
        <div key={day} className={`p-0.5 ${statusClass} text-white text-center rounded text-xs`}>
          {statusIcon}
        </div>
      );
    }

    return progressElements;
  };

  const formatGoalDuration = (duration) => {
    return `${duration} day${duration > 1 ? 's' : ''}`;
  };

  const calculateCompletionPercentage = (progress, duration) => {
    const completedDays = Object.values(progress).filter(day => day.status === 'completed').length;
    return Math.round((completedDays / duration) * 100);
  };

  const handleShareClick = async (platform) => {
    if (!shareUrl) {
      try {
        const response = await api.post('/api/create-share-progress', {
          progress_data: progressData,
          user_streak: userStreak,
          user_rank: userRank
        });

        if (response.status === 200) {
          setShareUrl(response.data.share_url);
          toast.success('Shareable URL created!');
          setShareNow(true);
        } else {
          console.error('Failed to create shareable URL', response.data.error);
          toast.error('Failed to create shareable URL');
          return;
        }
      } catch (error) {
        console.error('Error creating shareable URL:', error);
        toast.error('Error creating shareable URL');
        return;
      }
    } else {
      setShareNow(true);
    }

    if (shareNow) {
      const url = shareUrl;
      if (platform === 'facebook') {
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`, '_blank');
      } else if (platform === 'twitter') {
        window.open(`https://twitter.com/share?url=${encodeURIComponent(url)}&text=Check out my progress!&hashtags=progress,goals`, '_blank');
      } else if (platform === 'whatsapp') {
        window.open(`https://api.whatsapp.com/send?text=Check out my progress! ${encodeURIComponent(url)}`, '_blank');
      }
    }
  };

  const getTrophy = (rank) => {
    switch (rank) {
        case 1:
            return <FaMedal className="text-yellow-500" title="Gold Trophy" />;
        case 2:
            return <FaMedal className="text-gray-400" title="Silver Trophy" />;
        case 3:
            return <FaMedal className="text-orange-500" title="Bronze Trophy" />;
        default:
            return null;
    }
  };

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen px-4 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
          <div className="flex justify-between items-start">
            <h2 className="text-2xl font-bold mb-4">Share Your Progress</h2>
            <button
              onClick={onClose}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Close
            </button>
          </div>

          <div className="mb-4 border-b pb-4">
            {progressData.map((goal) => (
              <div key={goal.title} className="mb-4">
                <h3 className="text-xl font-semibold flex items-center">
                  {goal.title} 
                  <span className="ml-2 text-sm font-normal flex items-center">
                    <FaCalendarAlt className="mr-1" /> {formatGoalDuration(goal.duration)}
                    <FaPercentage className="ml-2 mr-1" /> {calculateCompletionPercentage(goal.progress, goal.duration)}%
                  </span>
                </h3>
                <div className="grid grid-cols-7 gap-1">
                  {renderProgress(goal.progress, goal.duration)}
                </div>
              </div>
            ))}
          </div>

          <div className="mb-4 p-4 bg-gray-100 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold mb-2 flex items-center">Your Streak <FaFire className="ml-2 text-red-500" /></h3>
            <p>Current Streak: <span className="font-bold">{userStreak.current_streak}</span></p>
            <p>Longest Streak: <span className="font-bold">{userStreak.longest_streak}</span></p>
          </div>

          <div className="mb-4 p-4 bg-gray-100 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold mb-2">Your Rank</h3>
            <p className="flex items-center">
                Your rank is: <span className="font-bold ml-1">{userRank}</span> {getTrophy(userRank)}
            </p>
          </div>

          <div className="flex justify-center space-x-4 mt-4">
            <button onClick={() => handleShareClick('facebook')}>
              <FacebookIcon size={32} round />
            </button>
            <button onClick={() => handleShareClick('twitter')}>
              <TwitterIcon size={32} round />
            </button>
            <button onClick={() => handleShareClick('whatsapp')}>
              <WhatsappIcon size={32} round />
            </button>
          </div>
          <ToastContainer />
        </div>
      </div>
    </div>
  );
};

export default ShareProgressCard;
