// src/components/NotFound.js

import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <div className="text-center">
                <h1 className="text-6xl font-extrabold text-gray-800">404</h1>
                <p className="mt-2 text-2xl text-gray-600">
                    Oops! Page not found.
                </p>
                <p className="mt-2 text-md text-gray-500">
                    The page you are looking for does not exist or has been moved.
                </p>
                <Link
                    to="/"
                    className="mt-6 inline-block bg-blue-600 hover:bg-blue-700 text-white px-6 py-3 rounded-md text-lg font-medium shadow-md transition-transform transform hover:scale-105"
                >
                    Go Back Home
                </Link>
                <div className="mt-8">
                    <p className="italic text-gray-400">
                        "Don't worry, even the best explorers get lost sometimes!"
                    </p>
                </div>
            </div>
        </div>
    );
};

export default NotFound;
